import 'magnific-popup';

const locations = [
	{
		'city': 'Amsterdam',
		'shop': 'Schiffmacher & Veldhoen',
		'address': 'Ceintuurbaan 416, 1074 EA',
		'times': '11AM-7PM',
		'url': ''
	},
	{
		'city': 'Athens',
		'shop': 'Sake Tattoo',
		'address': 'Κολοκοτρώνη 9 &, Ανδρέα Γκίνη 6, Χαλάνδρι 152 33',
		'times': '10AM-8PM',
		'url': ''
	},
	{
		'city': 'Atlanta',
		'shop': 'Liberty Tattoo',
		'address': '755 Ponce De Leon Avenue NE',
		'times': '12PM-10PM',
		'url': ''
	},
	{
		'city': 'Austin',
		'shop': 'Resurrection Tattoo',
		'address': '1309 E 4th Street',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Baltimore',
		'shop': 'Eastern Vintage Tattoo',
		'address': '2125 Eastern Avenue',
		'times': '12PM-10PM',
		'url': ''
	},
	{
		'city': 'Berlin',
		'shop': 'Blackfisk Tattoo',
		'address': 'Reichenberger Str. 133, 10999',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Boston',
		'shop': 'Redemption Tattoo',
		'address': '2090 Massachusetts Avenue, Cambridge',
		'times': '11AM-8PM',
		'url': ''
	},
	{
		'city': 'Brussels',
		'shop': 'La Boucherie Moderne',
		'address': 'Place du Jeu de Balle 26, 1000',
		'times': '12PM-7PM',
		'url': ''
	},
	{
		'city': 'Buenos Aires',
		'shop': 'American Tattoo',
		'address': 'Avenida Santa Fe 1670 Loc. 7 y 31, Rodríguez Peña 1023, C1060',
		'times': '11AM-8PM',
		'url': ''
	},
	{
		'city': 'Charlotte',
		'shop': 'Canvas Tattoo & Art Gallery',
		'address': '3012 N Davidson Street',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Chicago',
		'shop': 'Code Of Conduct Tattoo',
		'address': '14 E 11th Street',
		'times': '2PM-8PM',
		'url': ''
	},
	{
		'city': 'Columbus',
		'shop': 'Evolved Body Art',
		'address': '2520 Summit St.<br>1980 N. High St.<br>2413 N. High St.',
		'times': '12PM-11PM',
		'url': ''
	},
	{
		'city': 'Copenhagen',
		'shop': 'Handshake Studio',
		'address': 'Sølvgade 34 kl, 1307 København',
		'times': '12AM-8PM',
		'url': ''
	},
	{
		'city': 'Denver',
		'shop': 'Think Tank Tattoo',
		'address': '172 S Broadway',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Des Moines',
		'shop': 'Iron Heart Tattoo',
		'address': '2815 Beaver Avenue #108',
		'times': '12PM-7PM',
		'url': ''
	},
	{
		'city': 'Detroit',
		'shop': 'Harlequin Tattoo',
		'address': '10021 Joseph Campau Avenue, Hamtramck',
		'times': '1PM-8PM',
		'url': ''
	},
	{
		'city': 'Dublin',
		'shop': 'The Ink Factory',
		'address': '19 Parliament Street',
		'times': '12PM-7PM',
		'url': ''
	},
	{
		'city': 'Helsinki',
		'shop': 'Old 7 Tattoo',
		'address': 'Caloniuksenkatu 3, 00100',
		'times': '10AM-8PM',
		'url': ''
	},
	{
		'city': 'Honolulu',
		'shop': 'Old Ironside Tattoo',
		'address': '1033 Smith Street',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Houston',
		'shop': 'Scorpion Studios Tattoo',
		'address': '1401 Westheimer Road',
		'times': '2PM-12AM',
		'url': ''
	},
	{
		'city': 'Istanbul',
		'shop': 'Tattoom Gallery',
		'address': 'Teşvikiye Mah. Hüsrev Gerede Cd. 60/1 Nişantaşı,',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Kansas City',
		'shop': 'Irezumi Tattoo',
		'address': '7406 Wornall Road',
		'times': '11AM-10PM',
		'url': ''
	},
	{
		'city': 'Las Vegas',
		'shop': 'Trip Ink Tattoo',
		'address': '5115 Dean Martin Drive #906',
		'times': '10AM-10PM',
		'url': ''
	},
	{
		'city': 'Lisbon',
		'shop': 'Queen Of Hearts Tattoos',
		'address': 'R. da Rosa 75, 1200-382',
		'times': '11AM-8PM',
		'url': ''
	},
	{
		'city': 'London',
		'shop': 'The Black Heart',
		'address': '3 Greenland Pl, Camden Town',
		'times': '7PM-9PM',
		'url': ''
	},
	{
		'city': 'Los Angeles',
		'shop': 'Shamrock Social Club',
		'address': '9026 Sunset Blvd, West Hollywood',
		'times': '1PM-1AM',
		'url': ''
	},
	{
		'city': 'Mexico City',
		'shop': 'Not A Gallery',
		'address': 'Bucareli 120, Colonia Centro, Juárez, Cuauhtémoc, 06000',
		'times': '11:30AM-8:30PM',
		'url': ''
	},
	{
		'city': 'Miami',
		'shop': 'Tattoo And Co',
		'address': '3449 NE 1st Avenue, Unit 110',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Milan',
		'shop': 'Milano City Ink',
		'address': 'Viale Papiniano, 22a, 20123',
		'times': '11:30AM-6:30PM',
		'url': ''
	},
	{
		'city': 'Minneapolis',
		'shop': 'Black Coffin Tattoo',
		'address': '2480 University Ave W, St Paul',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Montreal',
		'shop': 'Mtl Tattoo',
		'address': '3933 Rue St-Denis',
		'times': '12PM-9PM',
		'url': ''
	},
	{
		'city': 'Moscow',
		'shop': 'Faux Pas Tattoo',
		'address': 'ТРЕХПРУДНЫЙ ПЕРЕУЛОК 8, ПОДЪЕЗД 1, ДОМОФОН 4',
		'times': '1PM-9PM',
		'url': ''
	},
	{
		'city': 'Nashville',
		'shop': 'Hart & Huntington Tattoo & Co.(Nashville)',
		'address': '137 2nd Ave N #201',
		'times': '10AM-10PM',
		'url': ''
	},
	{
		'city': 'New Orleans',
		'shop': 'Sailor\'S Cross & Gallery',
		'address': '646 Tchoupitoulas Street',
		'times': '10AM-6PM',
		'url': ''
	},
	{
		'city': 'New York City',
		'shop': 'Inked Nyc',
		'address': '150 W 22nd Street',
		'times': '12PM-12AM',
		'url': ''
	},
	{
		'city': 'Oakland',
		'shop': 'Sacred Tattoo',
		'address': '472 7th Street',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Orlando',
		'shop': 'Hart & Huntington Tattoo & Co. (Orlando)',
		'address': '6000 Universal Blvd #745a',
		'times': '10AM-12AM',
		'url': ''
	},
	{
		'city': 'Oslo',
		'shop': 'Attitude Tattoo',
		'address': 'Pilestredet 47A, 0350',
		'times': '5PM-8PM',
		'url': ''
	},
	{
		'city': 'Paris',
		'shop': 'Landscape Tattoo',
		'address': '14 Rue Keller, 7501',
		'times': '5PM-10PM',
		'url': ''
	},
	{
		'city': 'Philadelphia',
		'shop': 'Seven Swords Tattoo Company',
		'address': '2580 Frankford Avenue',
		'times': '12PM-8PM',
		'url': ''
	},
	{
		'city': 'Phoenix',
		'shop': 'Golden Rule Tattoo',
		'address': '918 N 6th Street',
		'times': '11AM-5PM',
		'url': ''
	},
	{
		'city': 'Pittsburgh',
		'shop': 'In The Blood Tattoo',
		'address': '2005 E Carson Street #1',
		'times': '12PM-7PM',
		'url': ''
	},
	{
		'city': 'Portland',
		'shop': 'Ever True Tattoo',
		'address': '213 SW Ash Street',
		'times': '1PM-7PM',
		'url': ''
	},
	{
		'city': 'Prague',
		'shop': 'Black House Tattoo',
		'address': 'Opatovická 160/18, 110 00 Nové Město',
		'times': '11AM-11PM',
		'url': ''
	},
	{
		'city': 'Providence',
		'shop': 'Providence Tattoo',
		'address': '401 Atwells Avenue',
		'times': '12PM-9PM',
		'url': ''
	},
	{
		'city': 'Rome',
		'shop': 'Mater Medusa Tattoo Shop',
		'address': 'Via Carmelo Maestrini, 162, 00128',
		'times': '10AM-8PM',
		'url': ''
	},
	{
		'city': 'Sao Paulo',
		'shop': 'Black Tree Ink',
		'address': 'R. Cristiano Viana, 119 - Pinheiros',
		'times': '1PM-9PM',
		'url': ''
	},
	{
		'city': 'Salt Lake City',
		'shop': 'Big Deluxe Tattoo',
		'address': '662 State Street',
		'times': '11:30AM-10PM',
		'url': ''
	},
	{
		'city': 'San Francisco',
		'shop': 'Let It Bleed Tattoo',
		'address': '1124 Polk Street',
		'times': '1PM-8PM',
		'url': ''
	},
	{
		'city': 'Stockholm',
		'shop': 'Secret Location',
		'address': '',
		'times': '6PM-8PM',
		'url': 'https://www.ilikeradio.se/banditrock/ozzy/'
	},
	{
		'city': 'Sydney',
		'shop': 'The Darling Parlour',
		'address': '4/13 Beattie St, Balmain NSW 2041',
		'times': '11AM-6PM',
		'url': ''
	},
	{
		'city': 'Tallinn',
		'shop': 'Studio Malm',
		'address': 'Roseni 10, 10111 Tallinn',
		'times': '5PM-6PM',
		'url': ''
	},
	{
		'city': 'Toronto',
		'shop': 'Chronic Ink Tattoo',
		'address': '252 Eglinton Ave E, Toronto, ON M4P 1K2',
		'times': '1PM-8PM',
		'url': 'https://www.chronicinktattoo.com/chronic-ink-ozzy-osbourne-listening-event/'
	},
	{
		'city': 'Vienna',
		'shop': 'Rattlesnake Tattoo',
		'address': 'Kirchengasse 3, 1070 Wien',
		'times': '10AM-8PM',
		'url': ''
	},
	{
		'city': 'Warsaw',
		'shop': 'Juniorink Studio',
		'address': 'plac Konstytucji 2/25, 00-552',
		'times': '10AM-6PM',
		'url': ''
	},
	{
		'city': 'Washington DC',
		'shop': 'Tattoo Paradise',
		'address': '2444 18th Street NW',
		'times': '1PM-6PM',
		'url': ''
	},
	{
		'city': 'Wroclaw',
		'shop': 'Zajawa Tattoo',
		'address': 'Ul. Matejki 3a/3, Wrocław',
		'times': '11AM-7PM',
		'url': ''
	}
];

export default function Events() {

	let html = '';
	locations.forEach( event => {
		if ( event.url ) {
			let extra = '';

			if ( event.city === 'Toronto' ) {
				extra = '<div class="extra">To confirm your spot please click RSVP</div>';
			}

			html += '<div>' +
			        '<div class="event-location">' + event.city + '</div>' +
			        '<div class="event-venue">' + event.shop + '<div class="event-address">' + event.address + '<br>' + event.times + '</div></div>' +
			        '<div class="event-links">' +
			        '<a href="' + event.url + '" target="_blank" class="external">RSVP</a>' +
			        extra +
			        '</div>' +
			        '</div>';
		} else {
			html += '<div>' +
			        '<div class="event-location">' + event.city + '</div>' +
			        '<div class="event-venue">' + event.shop + '<div class="event-address">' + event.address + '<br>' + event.times + '</div></div>' +
			        '<div class="event-links">' +
			        '<a href="#">RSVP</a>' +
			        '</div>' +
			        '</div>';
		}

	} );

	$( '#events' ).html( html );

	$( document ).on( 'click', '.event-links>a:not(.external)', function ( e ) {
		e.preventDefault();

		const location = $( this ).parent().siblings( '.event-location' ).text();
		const venue = $( this ).parent().siblings( '.event-venue' ).text();

		$( '#custom_field' ).val( location );
		$( '#custom_field_2' ).val( venue );

		$.magnificPopup.open( {
			items: {
				src: '#event-popup',
				type: 'inline'
			},
			closeBtnInside: false
		} );
	} );

	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).hide();
				$( '.thanks' ).show();
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );
}