const langs = {
	'us': {
		'.celebration': 'In Celebration Of His 17th Solo Album',
		'.big': '50+ Cities Around The World',
		'.info': '<p>Get an Ozzy-inspired tattoo - hear the album before anyone else</p>' +
		         '<p>Choose from exclusive designs made by your local tattoo parlour</p>' +
		         '<p>Hours Of Operation Below / Call Your Local Shop For Pricing</p>',
		'.first-come': 'First Come, First Serve / RSVP Does Not Guarantee Entry',
		'.thanks': 'WE HAVE RECEIVED YOUR RSVP!<br>' +
		           'PLEASE NOTE: IT IS FIRST COME, FIRST SERVE!<br>' +
		           'RSVP DOES NOT GUARANTEE ENTRY!'
	},
	'instanbul-turkey': {
		'.celebration': '17. SOLO ALBÜMÜNÜ KUTLAMAK ADINA',
		'.big': 'DÜNYANIN 50 ŞEHRİNDE',
		'.info': '<p>OZZY’DEN İLHAM ALINAN DÖVMEYİ YAPTIR + HERKESTEN ÖNCE YENİ ALBÜMÜ DİNLE</p>' +
		         '<p>SEÇİLİ DÖVME STÜDYOSUNDAN 17 ADET ÖZEL TASARIM ARASINDAN SEÇ</p>' +
		         '<p>ŞEHRE GÖRE ZAMAN VE FİYAT DEĞİŞİKLİK GÖSTEREBİLİR / DETAYLAR 17 ŞUBAT, PAZARTESİ SAAT 16:00’DA</p>',
		'.first-come': 'ÖNCE GELEN AVANTAJLI OLACAKTIR / REZERVASYON GARANTİSİ YOKTUR',
		'.thanks': 'REZERVASYONUN ALINDI!<br>' +
		           'NOT: ÖNCE GELEN AVANTAJLI OLACAKTIR!<br>' +
		           'REZERVASYON GARANTİSİ YOKTUR!'
	},
	'amsterdam-netherlands': {
		'.celebration': 'TER ERE VAN ZIJN 17DE SOLOALBUM',
		'.big': '50 STEDEN OVERAL TER WERELD',
		'.info': '<p>LAAT EEN OZZY TATTOO ZETTEN EN HOOR HET ALBUM ALS EERSTE</p>' +
		         '<p>KIES UIT ZEVENTIEN UNIEKE ONTWERPEN GEMAAKT DOOR EEN NEDERLANDSE TATTOO SHOP</p>' +
		         '<p>TIJDEN + PRIJZEN VARIËREN PER STAD / MEER DETAILS WORDEN MAANDAG 17 FEBRUARI OM 14.00 UUR BEKEND GEMAAKT</p>',
		'.first-come': 'WIE HET EERST KOMT, WIE HET EERST MAALT / RSVP BIEDT GEEN GARANTIE VOOR DEELNAME ',
		'.thanks': 'WE HEBBEN JE RSVP ONTVANGEN!<br>' +
		           'LET OP: WIE HET EERST KOMT, WIE HET EERST MAALT<br>' +
		           'RSVP BIEDT GEEN GARANTIE VOOR DEELNAME'
	},
	'montreal-canada-en': {
		'.celebration': 'IN CELEBRATION OF HIS 17TH SOLO ALBUM',
		'.big': '50+ CITIES AROUND THE WORLD',
		'.info': '<p>GET AN OZZY-INSPIRED TATTOO + HEAR THE ALBUM BEFORE ANYONE ELSE</p>' +
		         '<p>CHOOSE FROM EXCLUSIVE DESIGNS MADE BY YOUR LOCAL TATTOO PARLOUR</p>' +
		         '<p>TIMES + PRICES WILL VARY BY CITY / DETAILS REVEALED MONDAY, FEBRUARY 17TH AT 8AM ET</p>',
		'.first-come': 'FIRST COME, FIRST SERVE / RSVP DOES NOT GUARANTEE ENTRY ',
		'.thanks': 'WE HAVE RECEIVED YOUR RSVP!<br>' +
		           'PLEASE NOTE: IT IS FIRST COME, FIRST SERVE!<br>' +
		           'RSVP DOES NOT GUARANTEE ENTRY!'
	},
	'montreal-canada-fr': {
		'.celebration': 'POUR CÉLÉBRER SON 17E ALBUM SOLO',
		'.big': '50 VILLES AUTOUR DU MONDE',
		'.info': '<p>OBTENEZ UN TATOUAGE INSPIRÉ DE OZZY + ÉCOUTEZ L’ALBUM AVANT TOUT LE MONDE</p>' +
		         '<p>CHOISISSEZ PARMI 17 DESSINS EXCLUSIFS CRÉÉS PAR UN SALON DE TATOUAGE MONTRÉALAIS</p>' +
		         '<p>LES HEURES + LES PRIX PEUVENT VARIER / DÉTAILS ANNONCÉS LUNDI, LE 17 FÉVRIER À 8H HNE</p>',
		'.first-come': 'PREMIER ARRIVÉ, PREMIER SERVI / RSVP NE GARANTIT PAS L’ENTRÉE',
		'.thanks': 'NOUS RECEVONS VOTRE RSVP!<br>' +
		           'SUR UNE BASE DU PREMIER ARRIVÉ, PREMIER SERVI!<br>' +
		           'RSVP NE GARANTIT PAS L’ENTRÉE!'
	},
	'brussels-belgium-nl': {
		'.celebration': 'TER ERE VAN ZIJN 17DE SOLOALBUM',
		'.big': '50 STEDEN OVERAL TER WERELD',
		'.info': '<p>LAAT EEN OZZY TATTOO ZETTEN EN HOOR HET ALBUM ALS EERSTE</p>' +
		         '<p>KIES UIT ZEVENTIEN UNIEKE ONTWERPEN GEMAAKT DOOR EEN NEDERLANDSE TATTOO SHOP</p>' +
		         '<p>TIJDEN + PRIJZEN VARIËREN PER STAD / MEER DETAILS WORDEN MAANDAG 17 FEBRUARI OM 14.00 UUR BEKENDGEMAAKT</p>',
		'.first-come': 'WIE HET EERST KOMT, WIE HET EERST MAALT / RSVP BIEDT GEEN GARANTIE VOOR DEELNAME ',
		'.thanks': 'WE HEBBEN JE RSVP ONTVANGEN!<br>' +
		           'LET OP: WIE HET EERST KOMT, WIE HET EERST MAALT<br>' +
		           'RSVP BIEDT GEEN GARANTIE VOOR DEELNAME'
	},
	'brussels-belgium-fr': {
		'.celebration': 'POUR CÉLÉBRER SON 17E ALBUM SOLO.',
		'.big': '50 VILLES À TRAVERS LE MONDE.',
		'.info': '<p>FAITES VOUS UN TATOUAGE INSPIRÉ PAR L’UNIVERS D’OZZY + ÉCOUTEZ L\'ALBUM AVANT TOUT LE MONDE.</p>' +
		         '<p>CHOISISSEZ PARMI 17 DESIGNS EXCLUSIFS FAITS PAR VOTRE SALON DE TATOUAGE LOCAL.</p>' +
		         '<p>LES HEURES ET LES PRIX VARIERONT PAR VILLE / PLUS D’INFORMATIONS RÉVÉLÉES LE LUNDI 17 FÉVRIER À 14H (CET).</p>',
		'.first-come': 'PREMIER ARRIVÉ, PREMIER SERVI / LA RÉSERVATION NE GARANTIE PAS L\'ENTRÉE',
		'.thanks': 'DEMANDE DE RÉSERVATION REÇUE!<br>' +
		           'NOTE : PREMIER ARRIVÉ, PREMIER SERVI<br>' +
		           'LA RÉSERVATION NE GARANTIE PAS L\'ENTRÉE!'
	},
	'athens-greece': {
		'.celebration': 'ΜΕ ΑΦΟΡΜΗ ΤΟ 17ο SOLO ALBUM ΤΟΥ',
		'.big': '50 ΠΟΛΕΙΣ ΑΝΑ ΤΟΝ ΚΟΣΜΟ',
		'.info': '<p>ΚΑΝΤΕ ΕΝΑ TATTOO ΕΠΝΕΥΣΜΕΝΟ ΑΠΟ ΤΟΝ OZZY + ΑΚΟΥΣΤΕ ΠΡΩΤΟΙ ΤΟ ALBUM</p>' +
		         '<p>ΔΙΑΛΕΞΤΕ ΜΕΤΑΞΥ 17 ΑΠΟΚΛΕΙΣΤΙΚΩΝ DESIGNS ΑΠΟ ΤΟ SAKE TATTOO CREW </p>' +
		         '<p>ΟΙ ΩΡΕΣ ΚΑΙ ΟΙ ΤΙΜΕΣ ΔΙΑΦΕΡΟΥΝ ΑΝΑΛΟΓΑ ΤΗΝ ΠΟΛΗ/ΠΕΡΙΣΣΟΤΕΡΕΣ ΛΕΠΤΟΜΕΡΕΙΕΣ ΤΗΝ ΔΕΥΤΕΡΑ 17 ΦΕΒΡΟΥΑΡΙΟΥ ΣΤΙΣ 15.00</p>',
		'.first-come': 'TA TATTOO ΘΑ ΓΙΝΟΥΝ ΜΕ ΣΕΙΡΑ ΠΡΟΤΕΡΑΙΟΤΗΤΑΣ / Η ONLINE ΚΡΑΤΗΣΗ ΔΕΝ ΕΠΙΒΕΒΑΙΩΝΕΙ ΤΗΝ ΕΙΣΟΔΟ ΣΤΟ EVENT',
		'.thanks': 'ΕΥΧΑΡΙΣΤΟΥΜΕ ΓΙΑ ΤΗΝ ONLINE ΚΡΑΤΗΣΗ ΣΑΣ!<br>' +
		           'ΠΑΡΑΚΑΛΩ ΠΡΟΣΕΞΤΕ: ΤΟ EVENT ΘΑ ΛΕΙΤΟΥΡΓΗΣΕΙ ΜΕ ΣΕΙΡΑ ΠΡΟΤΕΡΑΙΟΤΗΤΑΣ!<br>' +
		           'Η ONLINE ΚΡΑΤΗΣΗ ΔΕΝ ΕΠΙΒΕΒΑΙΩΝΕΙ ΤΗΝ ΕΙΣΟΔΟ ΣΤΟ EVENT!'
	},
	'paris-france': {
		'.celebration': 'POUR CÉLÉBRER SON 17E ALBUM SOLO.',
		'.big': '50 VILLES À TRAVERS LE MONDE.',
		'.info': '<p>FAITES VOUS UN TATOUAGE INSPIRÉ PAR L’UNIVERS D’OZZY + ÉCOUTEZ L\'ALBUM AVANT TOUT LE MONDE.</p>' +
		         '<p>CHOISISSEZ PARMI 17 DESIGNS EXCLUSIFS FAITS PAR VOTRE SALON DE TATOUAGE LOCAL.</p>' +
		         '<p>LES HEURES ET LES PRIX VARIERONT PAR VILLE / PLUS D’INFORMATIONS RÉVÉLÉES LE LUNDI 17 FÉVRIER À 14H (CET).</p>',
		'.first-come': 'PREMIER ARRIVÉ, PREMIER SERVI / LA RÉSERVATION NE GARANTIE PAS L\'ENTRÉE',
		'.thanks': 'DEMANDE DE RÉSERVATION REÇUE!<br>' +
		           'NOTE : PREMIER ARRIVÉ, PREMIER SERVI<br>' +
		           'LA RÉSERVATION NE GARANTIE PAS L\'ENTRÉE!'
	},
	'milan-italy': {
		'.celebration': 'Per celebrare il suo 17° album solista.',
		'.big': 'In 50 città di tutto il mondo tra cui Milano.',
		'.info': '<p>Puoi farti un tatuaggio ispirato a quelli di Ozzy e ascoltare il suo nuovo album ‘Ordinary Man’ in anteprima</p>' +
		         '<p>Scegli tra i 17 disegni esclusivi realizzati da…</p>' +
		         '<p>Gli orari e i prezzi varieranno a seconda delle città. Maggiori dettagli saranno svelati dalle 14.00 del 17 febbraio 2020</p>',
		'.first-come': '“Chi prima arriva meglio si tatua!” / La conferma non garantisce l’ingresso',
		'.thanks': 'Abbiamo ricevuto la tua conferma !<br>' +
		           'Ti preghiamo di ricordare che “chi prima arriva meglio si tatua!”<br>' +
		           'La tua conferma non ti garantisce comunque l’entrata'
	},
	'moscow-russia': {
		'.celebration': 'В ЧЕСТЬ 17-ГО СОЛЬНОГО АЛЬБОМА',
		'.big': '50 ГОРОДОВ ПО ВСЕМУ МИРУ',
		'.info': '<p>ПОЛУЧИ ТАТУ, ВДОХНОВЛЕННУЮ OZZY + ПОСЛУШАЙ АЛЬБОМРАНЬШЕ ВСЕХ</p>' +
		         '<p>ВЫБИРАЙ ИЗ 17 ЭКСКЛЮЗИВНЫХ ДИЗАЙНОВ,ПРИДУМАННЫХ В ВАШЕМ САЛОНЕ</p>' +
		         '<p>ДЕТАЛИ ПОЯВЯТСЯ В ПОНЕДЕЛЬНИК, 17-ГОФЕВРАЛЯ В 16:00 ПО МОСКВЕ</p>',
		'.first-come': 'РЕЗЕРВ НЕ ГАРАНТИРУЕТ ДОПУСК – ПРОПУСК ПО ФАКТУ',
		'.thanks': 'МЫ ПОЛУЧИЛИ ВАШ РЕЗЕРВ!<br>' +
		           'ПОЖАЛУЙСТА, ИМЕЙТЕ ВВИДУ: ПРОПУСК В САЛОН ОСУЩЕСТВЛЯЕТСЯ ПО ФАКТУ В ЗАВИСИМОСТИ ОТ КОЛИЧЕСТВА ГОСТЕЙ!<br>' +
		           'РЕЗЕРВ НЕ ГАРАНТИРУЕТ ПРОПУСК!'
	},
	'stockholm-sweden': {
		'.celebration': 'In Celebration Of His 17th Solo Album',
		'.big': '50+ Cities Around The World',
		'.info': '<p>Get an Ozzy-inspired tattoo - hear the album before anyone else</p>' +
		         '<p>Choose from exclusive designs made by your local tattoo parlour</p>' +
		         '<p>Times + prices will vary by city / details revealed Monday, February 8th at 8AM ET</p>',
		'.first-come': 'First Come, First Serve / RSVP Does Not Guarantee Entry',
		'.thanks': 'WE HAVE RECEIVED YOUR RSVP!<br>' +
		           'PLEASE NOTE: IT IS FIRST COME, FIRST SERVE!<br>' +
		           'RSVP DOES NOT GUARANTEE ENTRY!'
	},
	'saopaulo-brazil': {
		'.celebration': 'COMEMORAÇÃO DO SEU 17º ÁLBUM SOLO',
		'.big': '50 CIDADES AO REDOR DO MUNDO',
		'.info': '<p>GANHE UMA TATUAGEM INSPIRADA NO OZZY + OUÇA O NOVO ÁLBUM ANTES DE TODO MUNDO<br>' +
		         'ESCOLHA UM DOS 17 DESENHOS CRIADOS POR UM TATUADOR LOCAL<br></p>' +
		         '<p>HORÁRIOS E PREÇOS VARIAM DE ACORDO COM AS CIDADES/INFORMAÇÕES SERÃO ANUNCIADAS </p>' +
		         '<p>SEGUNDA-FEIRA, 17 DE FEVEREIRO ÀS 10H DA MANHÃ (HORÁRIO DE BRASÍLIA)</p>',
		'.first-come': 'O ATENDIMENTO SERÁ POR ORDEM DE CHEGADA/O EMAIL DE INSCRIÇÃO NÃO GARANTE A ENTRADA',
		'.thanks': 'NÓS RECEBEMOS A SUA INSCRIÇÃO!<br>' +
		           'AVISO IMPORTANTE: O ATENDIMENTO SERÁ POR ORDEM DE CHEGADA<br>' +
		           'O EMAIL DE INSCRIÇÃO NÃO GARANTE A ENTRADA'
	},
	'mexico': {
		'.celebration': 'EN CELEBRACIÓN A SU 17AVO ALBUM COMO SOLISTA',
		'.big': '50 CIUDADES ALREDEDOR DEL MUNDO',
		'.info': '<p>OBTÉN UN TATUAJE INSPIRADO EN OZZY Y ESCUCHA EL NUEVO ALBUM ANTES QUE NADIE</p>' +
		         '<p>ESCOGE UNO DE LOS 17 DISEÑOS EXCLUSIVOS CON TU TATUADOR LOCAL</p>' +
		         '<p>HORARIOS Y PRECIOS PUEDE VARIAR POR CIUDAD / CONOCE TODA LA INFORMACIÓN EL LUNES 17 DE FEBRERO A LAS 8:00 AM ET</p>',
		'.first-come': 'LA RESERVACIÓN NO GARANTIZA LA ENTRADA.',
		'.thanks': 'RECIBIMOS TU RESERVACIÓN<br>' +
		           '¡ATENCIÓN! EL PRIMERO EN LLEGAR, EL PRIMERO EN TATUAR<br>' +
		           '¡LA RESERVACIÓN NO GARANTIZA TU ENTRADA!'
	},
	'lisbon-portugal': {
		'.celebration': 'COMEMORAÇÃO DO 17º ÁLBUM A SOLO',
		'.big': '50 CIDADES EM TODO O MUNDO',
		'.info': '<p>GANHA UMA TATUAGEM INSPIRADA NO OZZY + OUVE O ÁLBUM EM PRIMEIRA MÃO</p>' +
		         '<p>ESCOLHE ENTRE OS 17 DESENHOS EXCLUSIVOS DA AUTORIA DO TEU TATUADOR LOCAL (QUEEN OF HEARTS)</p>' +
		         '<p>HORÁRIOS DE FUNCIONAMENTO + PREÇOS VARIAM CONSOANTE A CIDADE / MAIS DETALHES SERÃO REVELADOS A 17 DE FEVEREIRO (2ª FEIRA), ÀS 13H00</p>',
		'.first-come': 'PRIORIDADE DE ATENDIMENTO POR ORDEM DE CHEGADA / CONFIRMAÇÃO ANTECIPADA NÃO GARANTE ENTRADA',
		'.thanks': 'RECEMBOS A TUA CONFIRMAÇÃO!<br>' +
		           'NOTA: PRIORIDADE DE ATENDIMENTO POR ORDEM DE CHEGADA<br>' +
		           'CONFIRMAÇÃO ANTECIPADA NÃO GARANTE ENTRADA'
	},
	'buenos-aires-argentina': {
		'.celebration': 'EN CELEBRACIÓN DE SU 17º ÁLBUM SOLISTA',
		'.big': '50 CIUDADES ALREDEDOR DEL MUNDO',
		'.info': '<p>OBTENDRÁN UN TATUAJE INSPIRADO EN OZZY + LA OPORTUNIDAD DE ESCUCHAR EL ÁLBUM ANTES DE SU LANZAMIENTO</p>' +
		         '<p>ELEGÍ ENTRE 17 DISEÑOS EXCLUSIVOS HECHOS POR TU SALÓN DE TATUAJES LOCAL</p>' +
		         '<p>HORARIOS + PRECIOS VARÍAN POR CIUDAD / DETALLES REVELADOS EL LUNES 17 DE FEBRERO A LAS 8 AM ET</p>',
		'.first-come': 'LOS TURNOS SON POR ORDEN DE LLEGADA / LA RESERVA NO GARANTIZA LA ENTRADA',
		'.thanks': + 'RECIBIMOS TU RESERVA!<br>' +
		           'IMPORTANTE: LOS TURNOS SON POR ORDEN DE LLEGADA<br>' +
		           'LA RESERVA NO GARANTIZA LA ENTRADA'
	},
	'prague': {
		'.celebration': 'U PŘÍLEŽITOSTI VYDÁNÍ 17. SÓLOVÉHO ALBA',
		'.big': '50 MĚST PO CELÉM SVĚTĚ',
		'.info': '<p>ZÍSKEJTE TETOVÁNÍ INSPIROVANÉ OZZYM A POSLECHNĚTE SI ALBUM DŘÍVE NEŽ KDOKOLIV JINÝ</p>' +
		         '<p>VYBERTE SI ZE 17 EXKLUZIVNÍCH NÁVRHŮ VYTVOŘENÝCH ČESKÝM TETOVACÍM SALÓNEM</p>' +
		         '<p>ČASY A CENY SE BUDOU LIŠIT PODLE MĚSTA / DETAILY BUDOU ZVEŘEJNĚNY V PONDĚLÍ 17. ÚNORA  VE 14 HODIN</p>',
		'.first-come': 'PRVNÍ PŘIJDE, PRVNÍ ZÍSKÁ / RSVP (POUHÁ ODPOVĚĎ) NEGARANTUJE VSTUP',
		'.thanks': 'DOSTALI JSME VAŠI ODPOVĚĎ!<br>' +
		           'UPOZORNĚNÍ: KDO PRVNÍ PŘIJDE, PRVNÍ ZÍSKÁ!<br>' +
		           'RSVP (POUHÁ ODPOVĚĎ) NEGARANTUJE VSTUP'
	},
	'german': {
		'.celebration': 'PRÄSENTIERT…EIN GLOBALES TATTOO EVENT INKL. ALBUM LISTENING DONNERSTAG, 20. FEBRUAR 2020 ZUR FEIER SEINES 17. SOLOALBUMS',
		'.big': 'IN ÜBER 50 STÄDTEN WELTWEIT',
		'.info': '<p>ERHALTE EINE OZZY-INSPIRIERTE TÄTOWIERUNG – HÖRE DAS ALBUM VOR ALLEN ANDEREN</p>' +
		         '<p>WÄHLE AUS EXKLUSIVEN DESIGNS, DIE DEIN LOKALES TATTOO-STUDIO ENTWIRFT</p>' +
		         '<p>ZEITEN + PREISE VARIIEREN NACH STADT / DETAILS WERDEN AM DONNERSTAG, 17. FEBRUAR UM 16 UHR VERÖFFENTLICHT</p>',
		'.first-come': 'WER ZUERST KOMMT, MALT ZUERST / DIE ANMELDUNG ÜBER „RSVP“ GARANTIERT KEINEN FIXEN PLATZ',
		'.thanks': 'Wir haben deine Antwort erhalten<br>' +
		           'BITTE BEACHTE: WER ZUERST KOMMT, MALT ZUERST<br>' +
		           'DIE ANMELDUNG ÜBER „RSVP“ GARANTIERT KEINEN FIXEN PLATZ<br>' +
		           'DETAILS WERDEN AM DONNERSTAG, 17. FEBRUAR UM 16 UHR VERÖFFENTLICHT'
	},
};


export default function Events() {
	$( '#lang_select' ).on( 'change', function ( e ) {
		e.preventDefault();
		const lang = $( this ).val();
		if ( typeof langs[lang] !== 'undefined' ) {
			for ( let selector in langs[lang] ) {
				$( selector ).html( langs[lang][selector] );
			}
		}
	} );
}
